<template>
  <!-- Generated using model-view-create.mustache -->
  <!-- Single Step Page -->
  <div class="create-page" v-if="!steps.length">
    <CForm
      v-on:submit.prevent="createSingleStep"
      novalidate
      class="create-page__form"
    >
      <FormBuilder
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
      />
    </CForm>
  </div>
  <!-- /Single Step Page -->
  <!-- Multi Step Page -->
  <div class="create-page" v-else>
    <div class="create-page__header">
      <CRow class="mr-0 ml-0">
        <CCol col="12" sm="6" class="d-flex align-items-center pl-0">
          <div class="zq-page-title-wrapper d-flex">
            <h3 class="zq-page-title">{{ texts.createPage.title }}</h3>
            <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
          </div>
        </CCol>
        <CCol col="12" sm="6">
          <ActionCreateBtns
            :currentStep="currentStep"
            :totalStep="totalStep"
            :finishAction="createEntity"
            @updateCurrentStep="updateCurrentStep"
            @next-step="nextStep"
          />
        </CCol>
      </CRow>
      <WizardHeader
        :currentStep="currentStep"
        :steps="steps"
        @updateCurrentStep="updateCurrentStep"
      />
    </div>
    <div class="content">
      <FormBuilder
        v-if="currentStep === 0"
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
        :isCreateHeader="false"
        :isSettingsStep="true"
      />
      <CreateRules
        v-if="steps.find(step => step.key === 'rules') && currentStep === getStepNumber('rules')"
        context="reward"
        @updateRulesData="updateRulesData"
        :stepNumber="getStepNumber('rules') + 1"
      />
      <CreateDependantOn
        v-if="steps.find(step => step.key === 'dependantOn') && currentStep === getStepNumber('dependantOn')"
        @updateDependantOnData="updateDependantOnData"
        @resetDependantValidation="resetDependantValidation"
        @updateCurrentTypes="updateDependantOnCurrentTypes"
        :isValid="dependantOnDataValidate"
        :dependantOnData="dependantOnData"
        :stepNumber="getStepNumber('dependantOn') + 1"
      />
      <CreateScheduling
        v-if="steps.find(step => step.key === 'scheduling') && currentStep === getStepNumber('scheduling')"
        @updateSchedulingData="updateSchedulingData"
        @resetValidate="resetSchedulingValidate"
        :schedulingData="schedulingData"
        :name="settingsData.name"
        :isFrom="schedulingDataValid_startDate"
        :isScheduleType="schedulingDataValid_scheduleType"
        :isEvery="schedulingDataValid_every"
        :isLimit="schedulingDataValid_scheduleOccurrencesLimit"
      />
      <CreateTranslations
        v-if="steps.find(step => step.key === 'translations') && currentStep === getStepNumber('translations')"
        :entityData="settingsData"
        :translatableFields="translatableFields"
        :translationsData="translationsData"
        @updated="updateTranslationsData"
        :stepNumber="getStepNumber('translations') + 1"
      />
      <CreateRewards
        v-if="steps.find(step => step.key === 'rewards') && currentStep === getStepNumber('rewards')"
        @updateRewardData="updateRewardData"
      />
      <SummaryStep
        v-if="currentStep === getStepNumber('summary')"
        :settingsData="settingsData"
        :rulesData="rulesData"
        :dependantOnData="steps.find(step => step.key === 'dependantOn') ? dependantOnData : {}"
        :schedulingData="steps.find(step => step.key === 'scheduling') ? schedulingData : {}"
        :rewardsData="rewardsData"
        :translationsData="translationsData"
        :stepNumber="getStepNumber('summary') + 1"
        :model="model"
        :translatableFields="translatableFields"
      />
      <TableLoader v-if="isShowLoader" />
    </div>
  </div>
  <!-- /Multi Step Page -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ActionCreateBtns from '@/shared/components/steps/ActionCreateBtns';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import WizardHeader from '@/shared/components/steps/Header';
import CreateTranslations from '@/shared/components/supportModels/translations/CreateTranslations';
import CreateRules from '@/shared/components/supportModels/rules/CreateRules';
import CreateDependantOn from '@/shared/components/supportModels/dependantOn/CreateDependantOn';
import CreateScheduling from '@/shared/components/supportModels/scheduling/CreateScheduling';
import CreateRewards from '@/shared/components/supportModels/rewards/AddRewards';
import SummaryStep from '@/shared/components/steps/SummaryStep';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { translationsTransform } from '@/utils/translationsUtils';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { rewards } from '@/config/descriptions/rewards.json';
import { rewardsTexts } from '@/config/pageTexts/rewards.json';
import rewardFields from '@/generated/ziqni/store/modules/rewards/fields';
import { dateUTC } from '@/utils/dateUTC';
import { cloneDeep, delay } from 'lodash';
import TableLoader from '@/components/table/Loader';


export default {
  name: 'CreateReward',
  components: {
    ActionCreateBtns,
    IconWithTooltip,
    WizardHeader,
    CreateTranslations,
    CreateRules,
    CreateDependantOn,
    CreateScheduling,
    CreateRewards,
    SummaryStep,
    TableLoader
  },
  data() {
    return {
      model: 'rewards',
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...rewards.create,
      },
      texts: {
        ...rewardsTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
      dependantOnData: {
        currentTypes: {},
        formData: {
          shouldMatchAtLeast: null,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        },
        selectedData: []
      },
      dependantOnDataValidate: null,
      schedulingData: {
        scheduleOccurrencesLimit: null,
        scheduleType: '',
        constraints: [],
        endDate: '',
        startDate: dateUTC(),
        every: [],
      },
      schedulingDataValid_startDate: null,
      schedulingDataValid_every: null,
      schedulingDataValid_scheduleOccurrencesLimit: null,
      schedulingDataValid_scheduleType: null,
      rewardsData: [],
      transformedRewards: [],
      rulesData: {},
      isShowLoader: false
    };
  },
  computed: {
    ...mapGetters('rewards', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('rewards', ['handleCreateRewards', 'handleGetRewards']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        rewardFields,
        rewardsTexts.createPage,
        rewards.create
      );

      const issueLimitIdx = this.formList.findIndex(item => item.key === 'issueLimit')
      this.formList.splice(issueLimitIdx, 0, {
        type: "SWITCH",
        label: 'Acknowledgement required',
        key: 'isAcknowledgmentRequired',
        tooltip: "",
        value: true
      })

      const delayIdx = this.formList.findIndex(item => item.key === 'delay')

      this.formList.splice(delayIdx, 0, {
        type: "SWITCH",
        label: 'Set Point In Time',
        key: 'setPointInTime',
        tooltip: "",
        value: false
      })

      let formSteps = [];

      this.formList.forEach(field => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.required) {
          this.requiredFields.push(field.key);
          if (field.type === 'NAMEKEY') {
            this.requiredFields.push('name');
          }
        }
      })

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
            this.requiredFields.scheduling.push('scheduleType')
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.steps.push(this.firstStep);
        let stepNumber = 1

        formSteps.forEach(step => {
          this.steps.push({
            title: step.label,
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: stepNumber,
          });
          this.stepKeys.push(step.key);

          stepNumber++;
        })

        this.translatableFields = rewardFields.baseFields.translatableFields;

        this.lastStep.step = stepNumber;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }
    },
    updateCurrentStep(val) {
      this.currentStep = val;
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);

      if (!invalidFields.length) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
      }
    },
    getInvalidFields() {
      let result = [];

      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
        if (this.settingsData['entityId'] === null || this.settingsData['entityId'] === '') {
          result.push('entityId');
        }
      }

      if (this.currentStep === 0) {
        if ((this.settingsData['pointInTime'] && this.settingsData['period']) || (!this.settingsData['pointInTime'] && !this.settingsData['period'])) {
          result.push('pointInTime', 'period');
        }

        if (this.settingsData['rewardRank']) {
          const str = this.settingsData['rewardRank']
          if (!str.match(/([1-9]|,|-)*$/)[0] || str.match(/([1-9]|,|-)*$/).index !== 0) {
            result.push('rewardRank');
          }
        }
      }

      if (this.getStepNumber('scheduling') === this.currentStep) {
        for (let key in this.schedulingData) {
          if (this.requiredFields.scheduling.includes(key) && (this.schedulingData[key] === null || this.schedulingData[key] === '')) {
            result.push(key);
          }
        }
      }

      return result;
    },
    getStepNumber(key) {
      let entityObject = this.steps.find(step => step.key === key);
      if (entityObject !== undefined && entityObject.hasOwnProperty('step')) {
        return entityObject.step;
      } else {
        return -1;
      }
    },
    updateSettingsData(val) {
      this.displaySetPointInTime(val.setPointInTime)

      if (val.pointInTime || val.period) {
        const pointInTimeElement = document.getElementsByName('pointInTime')[0];
        pointInTimeElement.closest('.zq--data-picker--wrap').classList.remove('zq-invalid')

        const periodElement = document.getElementsByName('period')[0];
        periodElement.parentElement.classList.remove('zq-invalid')
      }
      this.settingsData = val;
    },
    displaySetPointInTime(val) {
      const pointInTimeElement = document.getElementsByName('pointInTime')[0];
      const periodElement = document.getElementsByName('period')[0];
      const delayElement = document.getElementsByName('delay')[0];

      if (!pointInTimeElement) return

      if (val) {
        pointInTimeElement.closest('.zq--form-row').style.display = ''
        periodElement.closest('.zq--form-row').style.display = 'none'
        delayElement.closest('.zq--form-row').style.display = 'none'
      } else {
        pointInTimeElement.closest('.zq--form-row').style.display = 'none'
        periodElement.closest('.zq--form-row').style.display = ''
        delayElement.closest('.zq--form-row').style.display = ''

      }
    },
    updateDependantOnData(val) {
      this.dependantOnData = val;
    },
    resetDependantValidation() {
      this.dependantOnDataValidate = null;
    },
    updateDependantOnCurrentTypes(val) {
      this.dependantOnData.currentTypes = val;
    },
    updateSchedulingData(val) {
      this.schedulingData = val;
    },
    resetSchedulingValidate() {
      this.schedulingDataValid_startDate = null;
      this.schedulingDataValid_every = null;
      this.schedulingDataValid_scheduleOccurrencesLimit = null;
      this.schedulingDataValid_scheduleType = null;
    },
    updateTranslationsData(obj) {
      this.translationsMap = obj.map;
      this.translationsData = obj.val;
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.rewardType;
          delete reward.id;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;
          transformedRewards.push(reward);
        })
      }
      this.transformedRewards = transformedRewards;
    },
    updateRulesData(val) {
      this.rulesData = val;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    getRules() {
      let rules = [];
      for (const action in this.rulesData) {
        let ruleObject = {};

        ruleObject.entityId = '';
        ruleObject.action = action;
        ruleObject.context = 'reward';
        ruleObject.rules = this.rulesData[action];
        ruleObject.rules.type = 'condition';
        ruleObject.rules.lineNumber = 1;

        rules.push(ruleObject);
      }

      return rules
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.addConstraints;
        delete this.settingsData.setPointInTime;

        this.settingsData.delay = this.settingsData.delay ?? 0;
        this.settingsData.period = this.settingsData.period ?? 0;

        formData = {...this.settingsData};
      }
      if (Object.keys(this.translationsData).length) {
        formData.translations = translationsTransform(this.translationsData, this.translationsMap);
      }
      if (this.transformedRewards.length) {
        formData.rewards = this.transformedRewards;
      }
      if (Object.keys(this.schedulingData).length && this.schedulingData.scheduleType) {
        formData.scheduling = this.schedulingData;
      }
      if (Object.keys(this.rulesData).length) {
        formData.rules = this.getRules();
      }
      if (Object.keys(this.dependantOnData).length && this.dependantOnData.formData) {
        let isDependantOn = false;
        for (let key in this.dependantOnData.formData.dependantOn) {
          if (this.dependantOnData.formData.dependantOn[key].length) {
            isDependantOn = true;
          }
        }
        if (isDependantOn) {
          formData.dependantOn = {};
          formData.dependantOn.dependantOn = this.dependantOnData.formData.dependantOn;
          formData.dependantOn.entityType = 'member';
        }
      }

      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      if (formData.isAcknowledgmentRequired) {
        if (!Array.isArray(formData.constraints)) {
          formData.constraints = [];
        }

        formData.constraints.push('memberAcknowledgmentRequired');
      }

      delete formData.isAcknowledgmentRequired;

      const body = [];
      body.push(JSON.parse(JSON.stringify(formData)));

      this.isShowLoader = true;
      this.handleCreateRewards({createEntityRewardRequestArray: body})
        .then(data => {
          if (data.length) {
            delay(() => {
              this.isShowLoader = false;
              this.$router.push({
                name: 'PreviewReward',
                params: {
                  id: data[0].id,
                }
              })
            }, 1500)
          } else {
            console.log('Something went wrong');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
